form.payment_form{
    display: grid;
    grid-template-columns: 300px;
    justify-self: center;
    padding: 50px;
    border: solid black 1em;
    border-top: none;
    border-bottom-left-radius: 2em;
    border-bottom-right-radius: 2em;
}

input{
    margin-top: 1em;
}


div.StripeElement{
    margin-top: 1em;
}