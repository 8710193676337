div.cart{
    width: 50%;
    justify-self: center;
    border: solid black 1em;
    padding: 20px;
    border-radius: 2rem;
}


p.cart_total{
    text-align: right;
}

div.checkout{ 
  justify-self: center;
}
